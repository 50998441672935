import React from 'react'
import { TechDocPartDocuments, TechDocPartDownloads } from '../../../api/Types'
import DocsTechDocSectionWrapper from '../DocsTechDocSectionWrapper'
import { User } from '../../../../../api/Users/Types'
import { SignInConsumer } from '../../../../common/providers/SignInProvider'
import SignInModal from '../../../../common/modals/SignInModal'
import ModalOpen from '../../../../common/modals/ModalOpen'
import { downloadPartStepFile } from '../../../../../helpers/DownloadHelper'

type Props = TechDocPartDocuments

const downloadFile = (currentUser: User | undefined, path: string | (() => void)): void => {
  const pathCallback = typeof path === 'function' ? path : () => window.open(path)

  if (currentUser) {
    if (
      typeof currentUser.activated === 'boolean'
        ? !currentUser.activated
        : currentUser.activated !== ''
    ) {
      toastr.error('Please activate your account in order to download documents')
    } else {
      pathCallback()
    }
  }
}

const makeDownload = (url: string | (() => void), label: React.ReactNode): React.ReactNode => (
  <SignInConsumer>
    {signInContext => (
      <li className='part-documents-section-download'>
        <ModalOpen
          buttonComponent={
            <button className='part-documents-section-download-type'>{label}</button>
          }
          modalComponent={
            <SignInModal
              currentUser={signInContext.currentUser}
              successCallback={currentUser =>
                downloadFile(signInContext.currentUser || currentUser, url)
              }
            />
          }
        />
        <ModalOpen
          buttonComponent={
            <button className='part-documents-section-download-link'>Download</button>
          }
          modalComponent={
            <SignInModal
              currentUser={signInContext.currentUser}
              successCallback={currentUser =>
                downloadFile(signInContext.currentUser || currentUser, url)
              }
            />
          }
        />
      </li>
    )}
  </SignInConsumer>
)

const makeDownloads = (part: TechDocPartDownloads, idx: number): React.ReactNode => {
  const { download_2d_drawing, image_path, part_path, part_number, name } = part
  const download_3d_model = () => {
    downloadPartStepFile(part.id)
  }

  const partUrl = `/parts/${part_path}`

  return download_2d_drawing || download_3d_model ? (
    <li className='part-documents-section-item' key={idx}>
      <div className='part-documents-section-item-title'>
        <a className='part-documents-section-item-image' href={partUrl}>
          <img src={image_path} loading='lazy' alt={`Image of ${part_number}`} />
        </a>
        <a className='part-documents-section-item-link' href={partUrl}>
          <h3>
            {name}
            <span>{part_number}</span>
          </h3>
        </a>
      </div>
      <ul className='part-documents-section-downloads'>
        {download_2d_drawing &&
          makeDownload(
            download_2d_drawing,
            <>
              <i className='icon-2dmodel'></i>2D Drawing
            </>
          )}
        {download_3d_model &&
          makeDownload(
            download_3d_model,
            <>
              <i className='icon-3dmodel'></i>3D Model
            </>
          )}
      </ul>
    </li>
  ) : null
}

const TechDocPartDocuments: React.FunctionComponent<Props> = ({ parts, style }) => {
  return parts?.length ? (
    <DocsTechDocSectionWrapper cssNamespace='tech-doc-part-documents-section' style={style}>
      <h2 className='part-documents-section-title'>Technical Document Downloads</h2>
      <ul className='part-documents-section-items'>{parts.map(makeDownloads)}</ul>
    </DocsTechDocSectionWrapper>
  ) : null
}

export default TechDocPartDocuments
