import React from 'react'
import LandingPageSectionWrapper, {
  Props as LandingPageSecionProps,
} from '../../landing_pages/common/LandingPageSectionWrapper'

const DocsTechDocSectionWrapper: React.FunctionComponent<LandingPageSecionProps> = ({
  children,
  ...props
}) => {
  return (
    <LandingPageSectionWrapper {...props} defaultClassName='tech-doc-section'>
      {children}
    </LandingPageSectionWrapper>
  )
}

export default DocsTechDocSectionWrapper
