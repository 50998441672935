import React, { useContext } from 'react'

export const RequestContext = React.createContext<RequestContextProps>({} as RequestContextProps)

export const RequestConsumer = RequestContext.Consumer

export const useRequestContext = (): RequestContextProps => {
  const context = useContext(RequestContext)
  if (context === undefined) {
    throw new Error('useRequestProvider must be called within RequestContextProvider')
  }
  return context
}

type RequestContextProps = {
  readonly isBot: boolean
}

interface Props {
  readonly isBot: boolean
}

type State = {}

class RequestProvider extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <RequestContext.Provider
        value={{
          isBot: this.props.isBot,
        }}
      >
        {this.props.children}
      </RequestContext.Provider>
    )
  }
}
export default RequestProvider
