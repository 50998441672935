import React, { CSSProperties } from 'react'
import { toCssVariableName } from '../../../../helpers/StringHelper'
import { SectionStyle } from '../../api/Types'
import classNames from 'classnames'
import { useRequestContext } from '../../../common/providers/RequestProvider'

interface CSSPropertiesWithVariables extends CSSProperties {
  '--padding-top'?: string
  '--padding-bottom'?: string
  '--text-align'?: string
  '--background-image'?: string
}
export interface Props {
  readonly defaultClassName?: string
  readonly cssNamespace: string
  readonly style?: SectionStyle
  readonly extraClasses?: string | ReadonlyArray<string>
}

/**
 * Wrapper for landing page sections.
 * Allows sharing of common styles and markup.
 */
const LandingPageSectionWrapper: React.FunctionComponent<Props> = ({
  defaultClassName = 'landing-page-section',
  cssNamespace,
  style,
  extraClasses,
  children,
}) => {
  const { isBot } = useRequestContext()

  let extraClassNames: string[] = []

  if (Array.isArray(extraClasses)) {
    extraClassNames = [...extraClasses]
  } else if (extraClasses) {
    extraClassNames = [extraClasses as string]
  }

  const sectionCssVariables: CSSPropertiesWithVariables = {}

  for (const key in style) {
    let value = style[key]

    if (key === 'backgroundImage') {
      value = `url(${value})`
    }
    sectionCssVariables[toCssVariableName(key)] = value
  }

  const valueHasUnit = (value: string) => {
   return value.match(/(px|em|rem|vh|vw|%)$/)
  }

  const isValidPadding = (padding?: string | number) => {
    if (padding === undefined || padding === null) return false
    if (Number(padding) === 0) return true

    const paddingString = String(padding)
    return valueHasUnit(paddingString)
  }

  const classnames = classNames(defaultClassName, cssNamespace, extraClassNames, {
    [`bg-color-${style?.backgroundColor}`]: !!style?.backgroundColor,
    [`text-color-${style?.textColor}`]: !!style?.textColor,
    'landing-page-section--custom-padding-top': isValidPadding(sectionCssVariables['--padding-top']),
    'landing-page-section--custom-padding-bottom': isValidPadding(sectionCssVariables['--padding-bottom']),
    'landing-page-section--custom-text-align': !!sectionCssVariables['--text-align'],
    'landing-page-section--custom-background-image': !isBot && !!sectionCssVariables['--background-image'],
  })

  return (
    <section style={sectionCssVariables} className={classnames}>
      <div className={`${defaultClassName}-container`}>{children}</div>
    </section>
  )
}

export default LandingPageSectionWrapper
